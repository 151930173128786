import React, {useEffect} from 'react';
import {PageProps} from 'gatsby';

import SEO from '../components/seo';
import NavBar from '../components/navBar';

const ChatPage = (props: PageProps) => {
  return (
    <div className="min-h-screen w-screen">
      <SEO title="Chat" />
      <NavBar {...props} />
      <iframe src="https://cal.com/anthonydifiglio/free15?duration=15" className="inline min-h-screen w-screen" />
    </div>
  )
}
export default ChatPage;